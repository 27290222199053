export const API_URL = "https://api.secondstage.app"
//export const TOOLPRESS_API = "https://api-tool-press-yn26wrnl4q-ey.a.run.app"
export const TOOLPRESS_API = "https://toolpress-api.2ndstage.app"

export const THEME_CONFIG = {
    mode:"light",
    gradients: {
        default: ['#ed6f8b', '#ed6f8b'],
        home_question: ['#ed6f8b', '#ed6f8b'],
        applausometro: ['#ed6f8b', '#ed6f8b'],
        siNo:['#3a4de4', '#00d8a1'],
        sentiment: ['#3a4de4', '#00d8a1'],
        wordcloud: ['#EA4D95', '#E76E51'],
        qna: ['#EA4D95', '#E76E51'],
    },
    primary: '#c01623',
    secondary: '#263238',
    accent: '#EA4D95',
    text: '#000000',
    background:"#fee9d9",
    poll:{
        default:"rgba(237,111,139,0.43)",
        answered:"#ed6f8b",
        correct: '#3A722D',
        wrong: '#DB0A16'
    }
}