import React from "react";
import {Box, List, ListItem, styled, Typography} from "@mui/material";
import {THEME_CONFIG} from "../../../config";
import {motion, useMotionValue} from "framer-motion";
import SuccessCheck from "../../shared/SuccessCheck";

const {poll} = THEME_CONFIG

export default function QuizQuestion({risposte, corretta, hideResult = false, answer, sendAnswer, isPoll}) {
    let progress = useMotionValue(90)

    const RispostaStyled = styled(ListItem)({
        border: `2px solid ${poll.default}`,
        borderRadius: '0.7rem',
        background: poll.default,
        margin: '1rem 0',
        cursor: 'pointer',
    })

    const computeBkg = (r) => {
        if (!isPoll && hideResult === false) {
            if (answer !== '') {
                if (corretta === r) {
                    return poll.correct
                } else if (answer === r && answer !== corretta) {
                    return poll.wrong
                }
            }
        } else {
            if (answer === r) {
                return poll.answered
            }
        }

    }

    const Risposte = () => (
        risposte.map((r, i) => (
            <RispostaStyled key={i} style={{
                width: '100%',
                background: computeBkg(r),
                border: computeBkg(r) ? '2px solid ' + computeBkg(r) : `2px solid ${poll.default}`,
                color: 'white',
            }} onClick={() => rispondi(r)}>
                <Typography variant={"h6"} fontWeight='400'>
                    {i + 1}. {r}
                </Typography>
            </RispostaStyled>
        ))
    )

    const rispondi = (r) => {
        if (isPoll) {
            sendAnswer(r)
        } else {
            if (answer === '') {

                sendAnswer(r)
            }
        }
    }

    return (<>
            {(answer !== '') &&
                <Box margin={'auto'} style={{width: '18%'}}>
                    <motion.div
                        initial={{x: 0}}
                        animate={{x: 100}}
                        style={{x: progress}}
                        transition={{duration: 0.8}}
                    />
                    <SuccessCheck progress={progress}/>
                </Box>}
            <List>
                <Risposte/>
            </List>
        </>
    )
}
